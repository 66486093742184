<template>
  <v-toolbar flat class="mb-4">
    <v-toolbar-title>
      {{ title }}
      <span></span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="accent"
            router
            to="/company"
            v-on="on"
          >
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("user.name") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="accent"
            router
            to="/company/edit"
            v-on="on"
            v-if="permitted('Company.Update')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("common.edit") }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="permitted('User.Create')">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            router
            to="/company/usercreate"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("company.addUser") }}</span>
      </v-tooltip>
    </div>
  </v-toolbar>
</template>

<script>
import { Roles } from "@/_helpers/Role";

export default {
  name: "CompanyToolbar",

  props: {
    title: String,
    operations: Array,
  },

  computed: {
    Roles() {
      return Roles;
    },
  },
};
</script>

<style>
</style>